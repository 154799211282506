var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"700"}},[_c('v-card',{attrs:{"color":"#fafafa"}},[_c('v-toolbar',{attrs:{"dark":"","flat":"","color":"#1C3144"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('update:value', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Additional TPR Dates")])],1),_c('v-container',{staticClass:"ma-0"},[_c('section',{staticClass:"pa-4",staticStyle:{"border":"1px solid #ccc"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{ref:"beginDate",attrs:{"tabindex":"1","background-color":"#fff","clearable":"","label":"TPR Begin Date","prepend-inner-icon":"mdi-calendar","autocomplete":"off","dense":"","outlined":""},on:{"change":function($event){return _vm.formatDateInput('TPRBeginDate', _vm.dates.TPRBeginDate)}},model:{value:(_vm.dates.TPRBeginDate),callback:function ($$v) {_vm.$set(_vm.dates, "TPRBeginDate", $$v)},expression:"dates.TPRBeginDate"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"tabindex":"2","background-color":"#fff","label":"TPR End Date","autocomplete":"off","dense":"","outlined":"","prepend-inner-icon":"mdi-calendar","clearable":""},on:{"change":function($event){return _vm.formatDateInput('TPREndDate', _vm.dates.TPREndDate)}},model:{value:(_vm.dates.TPREndDate),callback:function ($$v) {_vm.$set(_vm.dates, "TPREndDate", $$v)},expression:"dates.TPREndDate"}})],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"tabindex":"3","icon":"","disabled":_vm.disabled,"large":"","color":"#00B24A"},on:{"click":_vm.addDate}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add")])])],1)],1),_c('v-data-table',{staticClass:"table",attrs:{"height":_vm.tableSize,"items":_vm.contract.TPRDates,"headers":_vm.headers,"items-per-page":_vm.pageSize,"fixed-header":"","hide-default-footer":"","dense":"","no-data-text":"No Dates Added"},scopedSlots:_vm._u([{key:"item.TPRBeginDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.TPRBeginDate)))])]}},{key:"item.TPREndDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.TPREndDate)))])]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectedItem=item, _vm.confirmDialog=true}}},[_c('v-icon',{attrs:{"color":"#D32F2F"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})],1)],1),_c('ConfirmDialog',{attrs:{"remove":_vm.remove,"action":'Delete',"selectedItem":_vm.selectedItem,"type":'Additional Date',"value":_vm.confirmDialog},on:{"update:value":function($event){_vm.confirmDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }